.previous-page{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
    overflow: hidden;
    position: relative;
}

.bolas1 {
    position: absolute;
    top: 16%;
    right: 75%;
    z-index: -1;
}

.bolas2 {
    position: absolute;
    top: 12%;
    left: 78%;
    z-index: -1;
}

.bolas3 {
    display:none;
}

@media(max-width:900px){
    .previous-page{
        gap:0;
    }
}

@media(max-width:600px){
    .bolas1 {
        display: none;
    }

    .bolas2 {
        display: none;
    }

    .bolas3 {
        display: block;
        position: absolute;
        top: 18%;
        left: 80%;
        z-index: -1;
    }
}

@media(max-width:400px){
    .bolas3 {
        left: 65%;
    }
}