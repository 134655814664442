.faqs-container {
    display: flex;
    flex-direction: column;

    padding-bottom: 12rem;
    
}

.title-container {
    width: 100%;
    padding-top: 5.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.down-container {
    height: 80vh;
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:5rem;
    height: fit-content;
}

.faqs-dropdowns {
    width: 80%;
    padding-left: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.faqs-text {
    text-align: center;
    color: #0000FF;
    font-size: 2.5rem;
    font-weight: 900;
}

.faqs-text2 {
    align-items: center;
    color: #BB00FF;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: left;
}

@media (max-width:900px) {
    .faqs-container {
        padding-bottom: 5rem;
    }

    .title-container {
        padding-top: 10rem;
    }
    .down-container {
        flex-direction: column;
        padding-top: 0;
        gap:5;
    }

    .faqs-dropdowns {
        padding-left: 2rem;
        padding-right: 1rem;
        width: 80%;
        padding-bottom: 6rem;
    }

    .faqs-text {
        font-size: 1.8rem;
    }

    .ok {
        order: -1;
        align-self: flex-end;
    }

    .ok img {
        display: none;
    }
}

@media (max-width:435px) {
    .title-container {
        padding-top: 5rem;
    }
    .faqs-text {
        font-size: 20px;
    }
    .faqs-text2{
        padding-top:2rem;
        font-size: 20px;

    }
    .faqs-dropdowns{
        gap:1rem;
        padding-bottom:3rem;
    }
    .faqs-container{
        padding-bottom: 0rem;
    }
}