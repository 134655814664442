@font-face {
  font-family: "Eras-itc-bold";
  src: url("../assets/eras-itc-bold/eras-itc-bold.ttf") format("truetype");
  
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/Poppins/Poppins-Regular.ttf") format("truetype");
  
}

body {
  margin: 0;
  font-family: "Eras-itc-bold"; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}