.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10rem;
    padding-right: 12rem;
    min-height: 30rem;
    gap:20rem;
}

.each-slide-effect span {
    color: #FFE76F;
    text-align: center;
    font-size: 44px;
    font-weight: 400; 
    text-transform: uppercase;
    padding: 0.5rem;
}

.people-container {
    background-color:#FFAC00;
    padding-left: 3rem;
    padding-right: 3rem;
}

.left-name {
    display: flex;
    gap:2rem;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.right-text{
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.70913rem
}

@media (max-width:1700px){
    .each-slide-effect>div{
        gap:14rem;
    }

}

@media (max-width:1500px){
    .each-slide-effect>div{
        gap:10rem;
        padding-right: 8rem;
        
    }

}

@media (max-width:1000px){
    .each-slide-effect>div{
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        padding-top: 5rem;
        
        padding-bottom: 5rem;
    }
    .right-text{
        font-size: 16px;
    }
    .each-slide-effect span{
        font-size:16px;
    }
}



@media (max-width:435px){
    .people-container{
        padding-left: 1rem;
        padding-right:1rem;
    }

    
    .each-slide-effect{
        margin:0;
        padding:0;
        gap:0;
        height:13.25rem;
    }
    .each-slide-effect>div{
        justify-content: start;
        padding:0;
        gap:0;
        margin: 0;
        height:13.25rem;
    }
    .active{
        height:13.25rem;
    }
    .left-name{
        width:3.375rem;
        padding-top:1rem;
        padding-bottom:0.5rem;
        font-size: 20px;
    }
    .right-text{
        display: flex;
        align-items: start;
        justify-content: center;
        font-size:10px;
        line-height: normal;
        width:18.75rem;
        height:7.5rem;
    }


    
}