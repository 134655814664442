.testemunhos-container {
    display: flex;
    gap: 8rem;
    flex-direction: column;
    align-items: center;
    padding: 3rem 15rem 10rem 15rem;
}

.testemunho {
    display: flex;
    width: 67.313rem;
    justify-content: center;
    align-items: center;
    gap: 5rem;
}

.testemunho-text {
    width: 42.313rem;
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;


}

.testemunho-year {
    font-weight: 400;
    font-size: 38px;
    text-align: center;


}

.year-color-0 {
    color: #0000FF;
}

.year-color-1 {
    color: #FF00FF;
}

.year-color-2 {
    color: #FFAC00;
}

@media (max-width:1400px) {
    .testemunho {
        width: 90%;
    }
}

@media (max-width:1200px) {

    .testemunho {
        flex-direction: column;
        gap: 2rem;
    }

    .testemunho-text {
        width: 100%;
        font-size: 15px;
    }
}

@media (max-width:1000px) {

    .testemunhos-container {
        gap: 1rem;
    }
}

@media (max-width:900px) {

    .testemunho {
        padding-top: 2rem;
        width: 100%;
        
    }

}

@media (max-width:800px) {

    .testemunhos-container {
        padding: 3rem 10rem 6rem 10rem;
    }

    .testemunho {
        width: 100%;
        
    }
}

@media (max-width:600px) {

    .testemunhos-container {
        padding: 2rem 7rem 4rem 1rem;
    }
    .testemunho {
        gap: 1rem;
        width: 100%;
        padding-top: 0;
    }
    .testemunho-text {
        font-size: 10px;
        width: 100%;
    }

    .testemunho-year {
        font-size: 16px;
    
    
    }
}
