.each-slide-effect-images>div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30rem;
    gap:0rem;
}

.past-container {
    background: #FFAC00;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.imagem-car{
    max-width:33rem;
    border-radius: 23px;
}

@media (max-width:1700px){
    .imagem-car{
        max-width: 29rem;
    }
}


@media (max-width:1500px){
    .imagem-car{
        max-width: 24rem;
    }
    .each-slide-effect-images>div {
        height: 28.5rem;
    }
    
}

@media (max-width:1370px){
    .imagem-car{
        max-width: 20rem;
    }
    .each-slide-effect-images>div {
        height: 25rem;
    }
}

@media (max-width:1170px){
    .imagem-car{
        max-width: 16rem;
    }
    .each-slide-effect-images>div {
        height: 22rem;
    }
}

@media (max-width:1000px){
    .imagem-car{
        max-width: 14rem;
    }
    .each-slide-effect-images>div {
        height: 20rem;
    }
}
@media (max-width:850px){
    .imagem-car{
        max-width: 20rem;
    }
    .each-slide-effect-images>div {
        height: 20rem;
    }
}

@media (max-width:500px){
    .imagem-car{
        max-width: 18rem;
    }
}

@media (max-width:450px){
    .imagem-car{
        max-width: 15rem;
    }
    .each-slide-effect-images>div {
        height: 18rem;
    }
    .past-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width:400px){
    .imagem-car{
        max-width: 14rem;
    }
    .each-slide-effect-images>div {
        height: 16rem;
    }
}
@media (max-width:340px){
    .imagem-car{
        max-width: 13rem;
    }
    .each-slide-effect-images>div {
        height: 14rem;
    }
    .past-container {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
