.mobile-display{
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
}

.navbar-container-mobile {
    display: flex;
    padding: 1.6rem;
    width: 80%;
    justify-content: space-between;
    background-color: white;
}

.video-dot-mobile{
    width: 40rem;
}

.okapa{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.buttons-container-a {
    text-decoration: none;
    cursor: pointer;
    font-size: 24px;
    line-height: 25px;
    transition: all 0.4s;

}
.buttons-container-a:hover{ 
    transform: scale(1.05); 
}
.edicoes-passadas{
    color:#FF00FF;
}
.estagios{
    color:#9C00FF;
}
.parceiros{
    color:#FF0000;
}

.faq{
    color:#FFAC00;
}

.perfil{
    color:#1C00FF;
}

.Logo-navbar-mobile{
    transition: all 0.4s;
}
.Logo-navbar-mobile:hover{
    transform: scale(1.05);
    cursor:pointer;
}


.registrations-mobile, .registrations-mobile div {
    display: flex;
    justify-content: center;
    align-items: center;	
    width: 11.313rem;
    height: 2.875rem;
    font-size: 24px;
    line-height: 25px;
    border-radius: 5px;
    padding: 0.2rem;
    background-color: #FFAC00;
    color:#FFE76F;
    cursor: pointer;

    transition: all 0.4s;
}


.registrations-mobile:hover {
    background-color: #FFE76F;
    color:#FFAC00;
    cursor: pointer;
}

.mobile-display-theme{
    display: flex;
    flex-direction: column;
    gap:1rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%; 
    overflow: hidden; 
    background: white;
    z-index: 999;
}

.navbar-container-mobile-theme{
    background: transparent;
    display: flex;
    padding: 2.5rem 2.5rem 0rem 2.5rem;
    justify-content: right;
}

.options-container-mobile{
    display: flex;
    flex-direction: column;
    color:white;
    padding:1.5rem 1.5rem 1.5rem 2.5rem;
    gap:2rem;
}

.options-container-text-mobile{
    font-size: 22px;
    font-weight: 800;
    line-height: 27.5px;
    text-align: left;

}

.bolasNavbar{
    position: absolute;
    top: 65%;
    left: 37%;

}


@media (max-width:500px){

    .video-dot-mobile{
        width: 20rem;
    }
    
}