.caroussel {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#EFEFEF;
    height: 17.688rem; 
}

.accenture{
    height: 5.938rem;
    width: 22.5rem;
    padding: 0 3rem 0 3rem;
}

.adecco {
    height: 5.938rem;
    width: 20.688rem;
    padding: 0 3rem 0 3rem;
}

.altri {
    height: 5.938rem;
    width: 8.75rem;
    padding: 0 3rem 0 3rem;
}

.Celfinet {
    height: 5.938rem;
    width: 17.5rem;
    padding: 0 3rem 0 3rem;
}

.bluepharma {
    height: 5.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.deloitte {
    height: 5.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.dspa {
    height: 5.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.enging {
    height: 5.938rem;
    width: 18.625rem;
    padding: 0 3rem 0 3rem;
}

.framedrop {
    height: 3.75rem;
    width: 33.75rem;
    padding: 0 3rem 0 3rem;
}

.goodbarber {
    height: 5.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.inovaAltice {
    height: 5.938rem;
    width: 10.625rem;
    padding: 0 1rem 0 1rem;
}

.ipn {
    height: 7.938rem;
    width: 18.625rem;
    padding: 0 3rem 0 3rem;
}

.redlight {
    height: 5.938rem;
    width: 20.625rem;
    padding: 0 3rem 0 3rem;
}

.sisint {
    height: 6.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.sodicentro {
    height: 5.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.tuu {
    height: 11.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

.worten {
    height: 5.938rem;
    width: 16.625rem;
    padding: 0 3rem 0 3rem;
}

@media (max-width: 435px) {
    .caroussel{
        height: 7.5rem;
        overflow-y: clip;
    }

    .tuu {
        width: 14.625rem;
        height: 5.938rem;
    }

    .worten {
        width: 14.625rem;
        height: 4.938rem;
    }
    .accenture{
        height: 4.938rem;
    }

}
