.faqs-dropdown-container {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    width: 100%;
}


.faqs-box{
    display: flex;
    gap:2rem;
    padding: 1.5rem;
    border: 2px;
    border-radius: 12px;
    justify-content: space-between;
    background: rgb(239, 239, 239);
    border-style: solid;
}
.faqs-box:nth-child(odd){
    border-color:#FF00FF;
    color: #FF00FF;
}
.faqs-box:nth-child(even){
    border-color:#9C00FF;
    color: #9C00FF;
}
.seta{
    transition: all 0.2s;
}
.seta-rotate{
    transform: rotate(-180deg);
}

.faq-inside{
    display: flex;
    flex-direction: column;
    gap:1.5rem;

    box-sizing: border-box;
}

.faq-question {
    font-family: "Poppins", sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    
}

.faq-answer {
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: left;
    color:black;
}

@media (max-width:900px) {
    .faqs-question{
        font-size: 1.10rem;
    }
    .faq-answer{
        font-size: 1rem;
    }
}


@media (max-width:435px){
    .faqs-box{
        gap:0;
    }
    .faq-question{
        font-size: 12px;
        line-height: normal;
    }
    .faq-answer{
        font-size: 12px;
        line-height: normal;
    }
    .faq-inside{
        gap:0.5rem;
    }
}