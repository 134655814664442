.homepage {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

@keyframes appear{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.logo-under-nav{
    animation: appear 1s;
}

.middle-element {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 11rem;
}

.middle-element-text {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-left: 20rem;
    width: 50%;
}

.middle-element-text-2 {
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    text-align: left;

}

.texto-dali {
    color: #0000FF;
    font-size: 32px; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.inscricao-boxx {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    background: #FFAC00;
    padding: 1.5rem;
    border-radius: 10px;

    font-size: 28px;
    font-weight: 600;
    line-height: 35.19px;
    text-align: left;
    color:#FFE76F;
    
    transition: all 0.4s;
}

.inscricao-boxx-container{
    padding-top: 12rem;
}

.inscricao-boxx:hover{
    background-color:#FFE76F;
    color:#FFAC00;
    cursor: pointer;
}


.mobile-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tittle-text {
    align-items: center;
    color:#0000FF;
    font-size: 44px;
    font-weight: 400;
    text-align: left;
}

.people-carroussel-container{
    position: relative;
}

.carroussel-top-circle{
    position: absolute;
    top: -100px;
    right: -100px;
    z-index: 1;

    height:245px;
}
.carroussel-bottom-circle{
    position: absolute;
    bottom: -100px;
    left: -65px;
    z-index: 1;

    height:232px;
}

.circleGroup1{
    height:546px;
    width:498px;

    left:-5px;
    top:90px;
}

.circleGroup2{
    height:595px;
    width:423px;

    right:-5px;
    top:25px;
}

.under-caroussel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40rem;

    padding-top:3rem;
    padding-bottom:10rem;
    position:relative;
    overflow: hidden;
}
.under-caroussel img{
    position: absolute;
}

.under-caroussel-text{
    text-align: center;
    color:#FF00FF;
    max-width: 900px;
    font-size:50px;
    letter-spacing: -2px;
    font-weight: 400;
}

.faqs-container{
    position: relative;

}

.circleGroup1Mobile{
    display: none;
}
.circleGroup2Mobile{
    display: none;
}
.circleMobile3{
    display: none;
}

.logo-under-nav{
    width: 100%;
    padding-bottom: 2rem;
}

@media (max-width: 1400px){
    .circleGroup1{
        position: absolute;
        top: 500px;
        left: -150px;
    }

    .circleGroup2{
        position: absolute;
        top: -350px;
        right: -100px;
    }

    .under-caroussel{
        align-items: center;
        justify-content: center;
    }

    .circleMobile3{
        display: none;
    }
}

@media (max-width: 1100px){
    .middle-element-text{
        padding-left: 2rem;
    }
}

@media (max-width: 750px){
    .middle-element-text{
        width: 90%;
    }
    .middle-element-circle{
        display:none;
    }
    .under-caroussel-text{
        font-size: 40px;
    }
}

@media (max-width:435px){

    .texto-dali{
        padding-top:1rem;
        font-size: 25px;
        
    }
    .inscricao-boxx-container{
        padding-top: 6rem;
    }
    .inscricao-boxx{
        width: 8.375rem;
        height:2.563rem;
        padding: 0.25rem 0.5rem;
        font-size: 16px;
        line-height: normal;
    }
    .middle-element-circle{
        display:none;
    }
    .middle-element{
        width:100%;
        padding-bottom:6rem;
    }
    .middle-element-text{
        padding-left:0;
        width:100%;
        gap:1rem;
    }
    .middle-element-text .tittle-text{
        font-size: 23px;
        text-align: center;
        padding-left:5rem;
        padding-right:5rem;
        
    }
    .middle-element-text-2{
        font-size:13px;
        padding-right:2rem;
        padding-left:2rem;

    }

    .under-caroussel-text{
        padding-left:3rem;
        padding-right:3rem;
        font-size:20px;
        letter-spacing:-0.5px;
        
        
    }

    .under-caroussel{
        height: 37.5rem;
        padding:0;
    }

    .under-caroussel .circleGroup2{
        display:none;
    }
    .under-caroussel .circleGroup1{
        display: none;
    }
    .circleGroup1Mobile{
        display: block;
        position: absolute;
        left:0;
        top:90px;
        height: 11.25rem;
        width: 15.625rem;
    }

    .circleGroup2Mobile{
        display: block;
        position: absolute;
        right:-10px;
        bottom:55px;
        height: 11.25rem;
        width: 15.625rem;
    }

    .circleMobile3{
        display: block;
        position: absolute;
        right:-12px;
        top:230px;
        height: 2.188rem;
        width: 2.188rem;
    }

    .ok{
        display:none;
    }

    .carroussel-bottom-circle{
        height:4.375rem;
        left:20px;
        top:-30px;
    }
    .carroussel-top-circle{
        height:3.75rem;
        right:2px;
        top:180px;
    }

    .logo-under-nav{
        width: 100%;
        padding-bottom: 2rem;
    }



}

