.footer-container{
    display: flex;
    flex-direction: column;
    padding-left: 3.5rem;
    padding-right: 20rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background-color:#BB00FF;
    gap: 2rem;
}

.contacts-text{
    font-size: 40px;
    font-weight: 800;
    line-height: 50px;
    text-align: left;
    color: rgba(248, 248, 248, 1);
    /* set uppercase */
    text-transform: uppercase;
}

.footer-inside-container{
    display: flex;
    gap:2rem;
    justify-content: space-between;
}

.info-display{
    display: flex;
    flex-direction: column;
    gap:2rem;
}

.info-text{
    font-family: "Poppins";
    font-size: 23px;
    font-weight: 400;
    line-height: 26.98px;
    text-align: left;
    color: rgba(248, 248, 248, 1);
}

.jek-logo{
    cursor: pointer;
}

@media (max-width:900px){
    .footer-inside-container{
        display: flex;
        flex-direction: column;
    }
}

@media (max-width:600px){
    .footer-container{
        padding-left: 2.5rem;
        padding-right:0.8rem;
    }
    .info-text{
        font-size: 19px;
    }
}
@media (max-width:435px){
    .contacts-text{
        font-size: 20px;
    }
    .info-text{
        font-size: 12px;
        line-height: normal;
        gap:1rem;
    }
    .footer-container{
        padding-top:1rem;
        padding-bottom: 1rem;
        background-color: #BB00FF;
        gap:0;
    }

    
    .jek-logo{
        width: 13rem;
    }
}