.cardEstagiosContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-transform: uppercase;
}
.cardEstagiosContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  max-width: 18.75rem;
}
.cardEstagiosContainer img {
  width: 100%;
}

.nomeEmpresaEstagio {
  color: #787878;
}
.tipoEstagio {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}
.logoEmpresaEstagio {
  max-width: 100%;
  padding: 0 1rem;
}
.linkEstagio {
  color: #9c00ff;
  font-size: 1.2rem;
  font-weight: 600;
  text-wrap:nowrap;
}
.descricaoEstagio {
  font-size: 1rem;
  text-align: center;
}
