.navbar-container {
    display: flex;
    padding-left: 2.6rem;
    padding-right: 2.6rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
    justify-content: space-between;
    background-color: white;
    align-items: center;
}
.logo{
    transition:all 0.4s;
}
.logo:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.under-nav {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 6.5rem;
}

.video-dot{
    width: 100vh;
}



.buttons-container {
    align-items: center;
    display: flex;
    width: 60%;
    justify-content: space-between;
}

.buttons-container-a {
    text-decoration: none;
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    transition: all 0.4s;

}
.buttons-container-a:hover{ 
    transform: scale(1.05); 
}
.edicoes-passadas{
    color:#FF00FF;
}
.estagios{
    color:#9C00FF;
}
.parceiros{
    color:#FF0000;
}

.faq{
    color:#FFAC00;
}



.registrations {
    
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    text-align: left;
    border-radius: 12px;
    padding:0.8rem 1.5rem;
    background-color: #FFAC00;
    color:#FFE76F;
    cursor: pointer;

    transition: all 0.4s;
} 

.registrations:hover {
    background-color: #FFE76F;
    color:#FFAC00;
    cursor: pointer;
}

@media (max-width: 1120px){
    .buttons-container a{
        font-size: 20px;
    }
    .registrations{
        font-size:20px;
    }
    .logo{
        transform: scale(0.8);
    }
    .logo:hover{
        transform: scale(0.9);
    }
}
@media (max-width: 950px){
    .buttons-container a {
        font-size:18px;
    }
    .registrations{
        font-size:18px;
    }
    .logo{
        transform: scale(0.6);
    }
    .logo:hover{
        transform: scale(0.7);
    }
}
@media (max-width: 860px){

    .buttons-container a {
        font-size:16px;
    }
    .registrations{
        font-size:16px;
    }
    .logo{
        transform: scale(0.5);
    }
    .logo:hover{
        transform: scale(0.6);
    }
}