.homepage-partners{
    position: relative;
    overflow: hidden;
}


.partners-title{
    display: flex;
    padding-right:7rem;
    padding-left:7rem;
}
.partners-title img{
    width:100%;
}

.partners-container{
    padding-top:4rem;
    padding-bottom:20rem;
    padding-right:5rem;
    padding-left:5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:8rem;
}

.partners-container div{
    display: flex;
    align-items: center;
    justify-content: center;
    height:20rem;
    width:35%;
}


.partners-container div img {
    width: 100%;
    max-height: 100%;
    transition: all 0.4s;
}
.partners-container div img:hover{
    transform: scale(1.05);
}

.left-img1{
    position: absolute;
    left: 0;
    top: 600px;
    z-index: -1;
}
.right-img1{
    position: absolute;
    right: 0;
    top: 500px;
    z-index: -1;
}

.left-img2{
    position: absolute;
    left: 0;
    top: 3900px;
    z-index: -1;
}
.right-img2{
    position: absolute;
    right: 0;
    top: 3500px;
    z-index: -1;
}
.left-img3{
    position: absolute;
    left: 0;
    bottom: 190px;
    z-index: -1;
}

.right-img3{
    position: absolute;
    right: 0;
    bottom: 100px;
    z-index: -1;
}
@media (max-width:1410px){
    .left-img{
        left:-150px;
    }
    .right-img{
        right:-150px;
    }
}
@media (max-width:1180px){
    .left-img{
        left:-200px;
    }
    .right-img{
        right:-200px;
    }
}

@media (max-width:900px){  
    .left-img{
        display: none;
    }
    .right-img{
        display: none;
    }

    .partners-title{
        padding-top:2rem;
        padding-right:1rem;
        padding-left:1rem;
    }

    .partners-container div{
        display: flex;
        align-items: center;
        justify-content: center;
        height:auto;
        width:50%;

    }

    
}

